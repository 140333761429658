<template>
  <div class="sales-chart">
    <LineChart
      :chart-data="chartData"
      :options="chartOptions"
      :height="500"
      :width="1000"
    />
  </div>
</template>

<script>
import moment from 'moment';
import LineChart from './LineChart.vue';
import tailwindConfig from '../helpers/tailwind';
export default {
  components: {
    LineChart,
  },

  props: {
    salesData: {
      type: Array,
      required: true,
    },

    target: {
      type: Number,
      required: true,
    },
  },

  computed: {
    chartData() {
      console.log(tailwindConfig);
      return {
        datasets: [
          {
            label: 'Actual',
            backgroundColor: 'rgba(110, 231, 183, 0.5)',
            borderColor: tailwindConfig.theme.backgroundColor.green['500'],
            opacity: '0.5',
            fill: true,
            data: this.salesData,
          },
          {
            label: 'Target',
            backgroundColor: 'rgba(221, 214, 254, 0.8)',
            borderColor: tailwindConfig.theme.backgroundColor.purple['300'],
            fill: true,
            data: [
              { x: moment().startOf('month'), y: 0 },
              { x: moment().endOf('month'), y: this.target },
            ],
          },
        ],
      };
    },
  },

  data() {
    return {
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              round: 'days',
              displayFormats: {
                day: 'D MMM',
              },
            },
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
