<template>
  <div class="sales-chart">
    <PieChart
      :chart-data="chartData"
      :options="chartOptions"
      :showValueLabels="true"
      :height="300"
      :width="300"
    />
  </div>
</template>

<script>
import PieChart from './PieChart.vue';
import tailwindConfig from '../helpers/tailwind';

export default {
  components: {
    PieChart,
  },

  props: {
    dealsData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    chartData() {
      console.log(tailwindConfig);
      return {
        labels: this.dealsData.map((d) => d.stage),
        datasets: [
          {
            backgroundColor: this.dealsData.map((d) => d.colourCode),
            data: this.dealsData.map((d) => d.count),
          },
        ],
      };
    },
  },

  data() {
    return {
      chartOptions: {
        responsive: false,
        layout: {
          padding: 0,
        },
        plugins: {
          datalabels: {
            color: 'white',
            font: {
              size: '28px',
              weight: '800',
            },
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
