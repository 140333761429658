<template>
  <div
    id="dashboard-page"
    class="
      w-screen
      h-screen
      grid grid-cols-3 grid-rows-8
      md:grid-cols-6 md:grid-rows-4
      gap-4
      p-4
    "
  >
    <div class="col-span-2 row-span-2">
      <Clock />
      <div class="mt-8 w-full">
        <h2 class="text-left text-3xl text-purple-700">
          Monthly Target:<span class="ml-3 font-medium">{{
            monthlyTargetFormatted
          }}</span>
        </h2>
        <h2
          class="text-left text-3xl mt-4"
          :class="{
            'text-red-500': !this.isOnTrack,
            'text-green-400': this.isOnTrack,
          }"
        >
          Current:<span class="ml-3 font-medium">{{
            currentAmountFormatted
          }}</span>
        </h2>
        <div class="relative rounded w-full bg-gray-300 h-8 mt-4">
          <div
            :style="`width: ${this.percentOfMonth * 100}%`"
            class="h-full rounded bg-purple-400"
          ></div>
          <div
            class="absolute inset-0 w-1/2 h-full rounded"
            :style="`width: ${
              this.targetPercent < 1 ? this.targetPercent * 100 : 100
            }%`"
            :class="{
              'bg-red-500': !this.isOnTrack,
              'bg-green-400': this.isOnTrack,
            }"
          ></div>
        </div>
      </div>
      <div class="mt-8 w-full flex">
        <div>
          <h1 class="text-6xl font-bold">{{ totalCount }}</h1>
          <p class="text-xl font-medium mt-2">Total Customers</p>
        </div>
        <div class="ml-8">
          <h1 class="text-6xl font-bold">{{ monthSalesCount }}</h1>
          <p class="text-xl font-medium mt-2">
            {{ moment().format('MMMM') }} Customers
          </p>
        </div>
      </div>
    </div>
    <div class="col-span-1 row-span-1">
      <div v-show="newSales.length">
        <h2 class="text-center mt-4 text-yellow-400 font-semibold text-4xl">
          NEW SALE
        </h2>
      </div>
    </div>
    <div class="col-span-2 row-span-2 col-start-5">
      <h2 class="text-4xl text-left">Recent Sales</h2>

      <div class="h-full overflow-scroll" ref="sales-scroll">
        <div>
          <div v-for="sale in recentSales" :key="sale.id" class="flex my-4">
            <p class="text-2xl text-left truncate w-80">
              {{ sale.customer ? sale.customer.name : '' }}
            </p>
            <p class="text-2xl ml-4 text-left font-semibold">
              {{ moneyFormatter.format(sale.subTotal) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-3 row-span-2">
      <SalesChart
        class="mt-8"
        :sales-data="salesChartData"
        :target="monthlyTarget"
      />
    </div>
    <div class="col-span-2 row-span-2 col-start-5 flex justify-center flex-col">
      <h2 class="text-4xl text-left">PFD Campaign Deals</h2>
      <DealsChart class="mt-4 -ml-4" :dealsData="dealsChartData" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import SalesChart from '../components/SalesChart.vue';
import DealsChart from '../components/DealsChart.vue';
import Clock from '../components/Clock.vue';

const saleAudioFile = require('../assets/audio/new-sale.wav');

export default {
  components: {
    Clock,
    SalesChart,
    DealsChart,
  },

  computed: {
    monthlyTarget() {
      return this.lastMonthTotal * 1.5;
    },

    monthlyTargetFormatted() {
      return this.moneyFormatter.format(this.monthlyTarget);
    },

    currentAmount() {
      return this.sales.reduce((acc, cur) => {
        return (acc += parseFloat(cur.subTotal));
      }, 0);
    },

    currentAmountFormatted() {
      return this.moneyFormatter.format(this.currentAmount);
    },

    isOnTrack() {
      return this.currentAmount >= this.monthlyTarget * this.percentOfMonth;
    },

    targetPercent() {
      return this.currentAmount / this.monthlyTarget;
    },

    salesChartData() {
      let rollingSum = 0;

      return this.sales.map((s) => ({
        y: (rollingSum += parseFloat(s.subTotal)),
        x: moment(s.createdTime),
      }));
    },

    recentSales() {
      const sortableList = [...this.sales];

      return sortableList
        .sort((a, b) => {
          return new Date(b.createdTime) - new Date(a.createdTime);
        })
        .slice(0, 6);
    },

    monthSalesCount() {
      return this.sales.length;
    },

    dealsChartData() {
      const stageMap = this.deals.reduce((acc, cur) => {
        if (!Object.prototype.hasOwnProperty.call(acc, cur.stage)) {
          acc[cur.stage] = 0;
        }

        acc[cur.stage]++;

        return acc;
      }, {});

      let data = [];

      for (const key in stageMap) {
        if (Object.hasOwnProperty.call(stageMap, key)) {
          let colourCode = '';

          if (key === 'Warm Lead') {
            colourCode = '#FCD34C';
          } else if (key === 'Demonstration Follow-up') {
            colourCode = '#BFDBFE';
          } else if (key === 'Closed Won') {
            colourCode = '#6EE7B7';
          } else if (key === 'Closed Lost') {
            colourCode = '#FDA5A5';
          }

          data.push({ stage: key, count: stageMap[key], colourCode });
        }
      }

      return data;
    },
  },

  data() {
    return {
      apiKey: '',
      lastMonthTotal: 0,
      moneyFormatter: new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }),
      sales: [],
      percentOfMonth: 0,
      percentInterval: null,
      salesInterval: null,
      salesAudio: new Audio(saleAudioFile),
      newSales: [],
      totalCount: 0,
      moment: moment,
      deals: [],
    };
  },

  watch: {
    async newSales(newVal, oldVal) {
      const cnt = newVal.filter((s) => !oldVal.includes(s)).length;

      for (let i = 0; i < cnt; i++) {
        this.newSalesAction();

        await new Promise((res) => {
          setTimeout(() => {
            res();
          }, 5000);
        });
      }
    },
  },

  methods: {
    async init() {
      await this.getSalesData();
      await this.getLastMonthSummary();
      this.getTotalCount();
      this.calcMonthPercentage();
      this.getDealsData();
    },

    async dataRefresh() {
      this.getSalesData();
      this.getTotalCount();
      this.getDealsData();
    },

    async getSalesData() {
      const startThisMonth = moment().startOf('month');
      const endThisMonth = moment().endOf('month');

      const thisMonth = await axios.get(
        'https://nopy3hjhwa.execute-api.ap-southeast-2.amazonaws.com/staging/sales',
        {
          headers: { 'X-API-Key': this.apiKey },
          params: {
            fromDate: startThisMonth.toISOString(),
            toDate: endThisMonth.toISOString(),
          },
        }
      );

      this.sales = thisMonth.data;

      const fiveMinutesAgo = moment().subtract(5, 'minute');

      this.newSales = this.sales
        .filter((s) => {
          return moment(s.createdTime).isAfter(fiveMinutesAgo);
        })
        .map((s) => s.id);
    },

    async getTotalCount() {
      const total = await axios.get(
        'https://nopy3hjhwa.execute-api.ap-southeast-2.amazonaws.com/staging/customers/count',
        {
          headers: { 'X-API-Key': this.apiKey },
        }
      );

      this.totalCount = total.data;
    },

    async getLastMonthSummary() {
      const startLastMonth = moment().subtract(1, 'month').startOf('month');
      const endLastMonth = moment().subtract(1, 'month').endOf('month');

      const lastMonth = await axios.get(
        'https://nopy3hjhwa.execute-api.ap-southeast-2.amazonaws.com/staging/sales/summary',
        {
          headers: { 'X-API-Key': this.apiKey },
          params: {
            fromDate: startLastMonth.toISOString(),
            toDate: endLastMonth.toISOString(),
          },
        }
      );

      this.lastMonthTotal = lastMonth.data._sum?.subTotal
        ? lastMonth.data._sum?.subTotal
        : 0;
    },

    async getDealsData() {
      const dealsRes = await axios.get(
        'https://nopy3hjhwa.execute-api.ap-southeast-2.amazonaws.com/staging/deals',
        {
          headers: { 'X-API-Key': this.apiKey },
          params: {
            campaignId: 'f798027b-5276-4280-b0ad-3502fc9e9f07',
          },
        }
      );

      this.deals = dealsRes.data;
    },

    newSalesAction() {
      this.salesAudio.play();
    },

    calcMonthPercentage() {
      const daysTotal = moment().daysInMonth();
      const daysCurrent = moment().date();

      this.percentOfMonth = parseFloat(daysCurrent) / parseFloat(daysTotal);
    },

    scroller() {
      const container = this.$ref['sales-scroll'];
      container.scrollTop = container.scrollHeight;
    },
  },

  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.percentInterval);
    clearInterval(this.salesInterval);
  },

  async created() {
    this.apiKey = this.$route.query.apiKey;
    await this.init();

    // update the percent every hour
    this.percentInterval = setInterval(this.calcMonthPercentage, 36000000);
    this.dataInterval = setInterval(this.dataRefresh, 60000);

    // Reload the page every 6 hours
    setTimeout(() => {
      location.reload();
    }, 21600000);
  },
};
</script>

<style lang="scss" scoped></style>
